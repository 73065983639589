import React, { useEffect, useContext, useState } from 'react';
import { graphql } from 'gatsby';
import flatten from 'lodash.flatten';
import Layout from 'components/layout';
import SEO from 'components/seo';
import { FeaturedMarketing } from 'components/CmsComponents/FeaturedMarketing';
import { SubMenu } from 'components/subMenu/index';
import Components from 'components/CmsComponents/';

import { HeaderContext } from 'context/header';

import styled from 'styled-components';
import { device } from 'components/device';

const GridContainer = styled.div`
  display: ${props => (props.isDesktop ? 'grid' : 'flex')};
  grid-template-columns: ${props =>
    props.isDesktop && 'auto repeat(12, 70px) auto'};
  column-gap: ${props => props.isDesktop && '30px'};
  flex-direction: ${props => !props.isDesktop && 'column'};
  padding: ${props => !props.isDesktop && '20px'};

  @media ${device.laptop} {
    flex-direction: column;
    padding-bottom: 0;
    padding-top: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const Part = styled.div`
  display: ${props => props.isDisplay};
  grid-column: ${props => props.gridColumn};
  border-bottom: ${props => props.borderBottom && '1px solid #C6C8CC'};
  padding-top: ${props => props.paddingTop && '-10px'};
  padding-bottom: ${props =>
    props.paddingBottomDesk
      ? props.paddingBottomDesk
      : props.paddingBottom && '60px'};
  border-top: ${props => props.borderTop && '1px solid #C6C8CC'};
  margin-top: ${props => props.marginTop};

  section :first-child {
    padding: ${props =>
      props.notPadding
        ? '0px 0 0 !important'
        : props.notPaddingTop && '0 0 70px !important'};
    p {
      padding-left: ${props => props.notLeft && '0'};
    }

    @media ${device.laptop} {
      width: ${props => props.notPaddingTop && '100%'};
      padding: ${props =>
        props.notPadding
          ? '0 0 0 !important'
          : props.notPaddingTop && '30px 0 !important'};
      border-bottom: ${props => props.notPaddingTop && 'unset'};
    }

    @media ${device.tablet} {
      padding: ${props => props.notPaddingTop && '0 0 30px !important'};
    }

    @media ${device.mobile} {
      margin: ${props => props.marginMob};
    }
  }

  section :last-child {
    padding: ${props => props.shortPadding && '20px 0 70px'};
    @media ${device.laptop} {
      padding: ${props => props.shortPadding && '0 0 30px'};
    }
  }

  section {
    div {
      div {
        h2 {
          width: ${props => props.largeTitle && '28rem'};

          @media ${device.laptop} {
            width: 100%;
          }
        }
      }
    }
  }

  :first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media ${device.laptop} {
      margin-bottom: 0;
    }

    @media ${device.tablet} {
      margin-bottom: ${props => (props.isFirstMarginBottom ? '0' : '1.875rem')};
    }

    @media ${device.mobile} {
      margin-bottom: 0;
    }
  }

  @media ${device.laptop} {
    flex-direction: column;
    margin-top: ${props => (props.marginTop ? props.marginTop : '0')};
    padding-top: ${props => props.paddingTop && '30px'};
    padding-bottom: ${props => props.notPaddingTop && '30px'};
    border-bottom: ${props =>
      props.borderBottom || props.test
        ? 'unset'
        : props.notPaddingTop && '1px solid #C6C8CC'};
    border-top: ${props => props.paddingTop && '1px solid #C6C8CC'};

    section {
      div {
        display: ${props => props.paddingTop && 'flex'};
      }
    }
  }

  @media ${device.tablet} {
    padding-top: ${props => props.paddingTop && '0'};
    border-top: ${props => props.paddingTop && 'unset'};
  }

  @media ${device.laptop} {
    border-bottom: ${props =>
      props.borderBottom ? '1px solid #C6C8CC' : 'none'};
  }
`;
const getMarkdown = (page, fragmentId, isMarkdown = false, assets = false) => {
  const { fragments } = page;
  const findFragment = fragments.find(item => item.id === fragmentId);

  if (isMarkdown) {
    const { markdown } = flatten(findFragment.localizations)[0];
    return markdown;
  } else if (assets) {
    return findFragment.assetpicker;
  } else {
    return flatten(findFragment.localizations);
  }
};
const EndowmentFund = ({
  data: {
    gcms: {
      site: { pages },
    },
  },
  pageContext,
}) => {
  const {
    setMainMarginTopLaptop,
    setMainMarginTopMobile,
    showAlertPostFeriado,
    isVisibleNovoSite,
  } = useContext(HeaderContext);
  const page = pages?.[0];
  const featuredMarketingContent = page?.featuredMarketing;
  const components = page?.components ?? [];
  const dataSeo = [
    {
      seo: {
        metaTitle: page?.seo?.metaTitle ?? '',
        metaDescription: page?.seo?.metaDescription ?? '',
      },
    },
  ];

  const [isDesktop, setDesktop] = useState(true);

  const updateMedia = () => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateMedia);
      return () => window.removeEventListener('resize', updateMedia);
    }
  });

  useEffect(() => {
    if (showAlertPostFeriado || isVisibleNovoSite) {
      setMainMarginTopLaptop('9.075rem');
      setMainMarginTopMobile('7.875rem');
    } else {
      setMainMarginTopLaptop('7.063rem');
      setMainMarginTopMobile('5.875rem');
    }
  }, [showAlertPostFeriado, isVisibleNovoSite]);

  return (
    <Layout>
      <SEO
        dataSeo={dataSeo}
        image={page?.featuredMarketing?.images?.[0]?.url ?? ''}
      />
      {featuredMarketingContent && (
        <FeaturedMarketing content={featuredMarketingContent} />
      )}
      <>
        <GridContainer isDesktop={isDesktop}>
          <Part
            gridColumn={'2 / -2'}
            noBorderTopLap
            notPadding
            notLeft
            shortPadding
          />
          <Part gridColumn={'2 / -2'} borderBottom>
            <SubMenu
              markdown={getMarkdown(page, 'ckud2sh28ssjl0c28glfiwyfg', false)}
              isMarginPageFundoPatrimonial
            />
          </Part>
        </GridContainer>
        {components.length >= 1 &&
          components?.map(component => {
            return Components(component);
          })}
      </>
    </Layout>
  );
};

export const EndowmentFundQuery = graphql`
  query EndowmentFundQuery {
    gcms {
      site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
        pages(where: { id: "cl3egsnwe073p0dlit04789pm" }) {
          featuredMarketing(locales: [pt_BR, en, es]) {
            id
            type
            breadcrumb
            backgroundColor {
              hex
            }
            images {
              handle
              width
              height
              url
            }
            activeHighlight
            highlight
            title
            fragments {
              id
              name
              type
              markdown
            }
          }
          fragments {
            id
            name
            localizations {
              singletexts
              markdown
              datafragment
            }
            assetpicker {
              handle
              width
              height
              url
            }
          }
          components(locales: [pt_BR, en, es]) {
            __typename
            ... on GraphCMS_SimpleContent {
              id
              name
              type
              title
              fragments {
                id
                name
                singletexts
                markdown
                datafragment
                assetpicker {
                  handle
                  width
                  height
                  url
                }
              }
            }
          }
          seo {
            metaTitle
            metaDescription
            noIndex
            localizations {
              metaTitle
              metaDescription
              noIndex
            }
          }
        }
      }
    }
  }
`;

export default EndowmentFund;
