import styled from 'styled-components';
import { Link } from 'gatsby';
import { device } from 'components/device';

export const ContainerSubMenu = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 4.375rem;
  margin-bottom: 4.375rem;

  .container-link-2 {
    margin-right: 0;
  }

  .links-submenu-position-0 {
    margin-bottom: 1.875rem;
  }

  @media ${device.laptop} {
    margin-bottom: ${props =>
      props.isMarginPageOrganograma
        ? '4.375rem'
        : props.isMarginPageVoluntariado
        ? '4.375rem'
        : props.isMarginPageRelatoriodeSustentabilidade
        ? '4.375rem'
        : props.isMarginPageDemostracaoFinanceiras
        ? '4.375rem'
        : props.isMarginPageFundoPatrimonial
        ? '4.375rem'
        : '0'};
    margin-top: ${props =>
      props.isMarginPageOrganograma
        ? '0'
        : props.marginTopSubMenuMob
        ? '2.5rem'
        : '4.375rem'};
  }
  @media ${device.tablet} {
    margin-top: ${props =>
      props.marginTopSubMenuMob
        ? '0'
        : props.isMarginPageRelatoriodeSustentabilidade
        ? '2.5rem'
        : props.isMarginPageDemostracaoFinanceiras
        ? '2.5rem'
        : '4.375rem'};
  }

  @media ${device.mobile} {
    margin-top: ${props => (props.marginTopSubMenuMob ? '0' : '1.875rem')};
    margin-bottom: 1.875rem;
    flex-direction: column;

    .links-submenu-position-0 {
      margin-bottom: 0.625rem;
      :last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export const ContainerMenuLink = styled.div`
  height: 1.375rem;
`;

export const ContainerLinks = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 24.375rem;
  margin-right: 1.875rem;
  margin-left: 1.875rem;

  :first-child {
    margin-left: 0;
    margin-right: 0;
  }

  :last-child {
    margin-left: 0;
    margin-right: 0;
  }

  @media ${device.mobile} {
    margin-bottom: 0.625rem;
    margin-right: 0;
    margin-left: 0;

    :first-child {
      margin-top: 0;
    }

    :last-child {
      margin-bottom: 0;
    }
  }
`;

export const SubMenuLink = styled(Link)`
  color: #45a7df;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.375rem;
`;

export const SubMenuLinkActive = styled.p`
  color: #45a7df;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.375rem;
`;
