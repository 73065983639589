import React, { useEffect, useState } from 'react';
import {
  ContainerSubMenu,
  SubMenuLink,
  ContainerMenuLink,
  ContainerLinks,
  SubMenuLinkActive,
} from './style';

export function SubMenu({
  markdown,
  marginTopSubMenuMob,
  isMarginPageOrganograma,
  isMarginPageVoluntariado,
  isMarginPageRelatoriodeSustentabilidade,
  isMarginPageDemostracaoFinanceiras,
  isMarginPageFundoPatrimonial,
}) {
  let resultData = [];

  const [pageNow, setPageNow] = useState([]);

  if (markdown) {
    resultData = markdown[0].datafragment[0];
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const replaceEnd = window.location.pathname.replaceAll('/', '');

      const resultLinks = resultData.menu_link.map(_ => {
        const testeb = _.links.map(a => ({
          ...a,
          active: a.link_menu.replaceAll('/', '') === replaceEnd,
        }));
        return { ..._, links: [...testeb] };
      });
      setPageNow(resultLinks);
    }
  }, []);

  return (
    <ContainerSubMenu
      isMarginPageDemostracaoFinanceiras={isMarginPageDemostracaoFinanceiras}
      isMarginPageRelatoriodeSustentabilidade={
        isMarginPageRelatoriodeSustentabilidade
      }
      isMarginPageVoluntariado={isMarginPageVoluntariado}
      isMarginPageOrganograma={isMarginPageOrganograma}
      marginTopSubMenuMob={marginTopSubMenuMob}
      isMarginPageFundoPatrimonial={isMarginPageFundoPatrimonial}
    >
      {pageNow?.map(el => {
        return (
          <ContainerLinks
            key={el.positionColumn}
            className={`container-link-${el.positionColumn}`}
          >
            {el.links.length > 0 &&
              el.links.map((result, ind) => (
                <ContainerMenuLink
                  key={ind}
                  className={`links-submenu-position-${ind}`}
                >
                  {result.active ? (
                    <SubMenuLinkActive>{result.name_menu}</SubMenuLinkActive>
                  ) : (
                    <SubMenuLink to={result.link_menu}>
                      {result.name_menu}
                    </SubMenuLink>
                  )}
                </ContainerMenuLink>
              ))}
          </ContainerLinks>
        );
      })}
    </ContainerSubMenu>
  );
}
